.skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    background-color: #FFDEB9;

    .skills-header {
        color: black;
        font-weight: 600;
        font-size: 50px;
        padding: 40px 0px 20px 0px;
    }
}