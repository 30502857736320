.site-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #680101;
    width: 100%;

    .nav-logo {
        height: 50px;
        padding: 0px;
        margin: -5px 0px 0px 0px;
    }

    .nav-logo:hover {
        opacity: 90%;
        transition: 0.3 ease;
    }

    input[type=checkbox] {
        display: none;
    }

    .hamburger {
        display: none;
        font-size: 24px;
        user-select: none;
        color: white;
    }

    .menu {
        display: flex;
        gap: 1em;
        font-size: 18px;

        li:hover {
            background-color: maroon;
            border-radius: 5px;
            transition: 0.3s ease;
            opacity: 90%;
        }

        li {
            padding: 5px 14px;
            font-weight: 600;
            list-style: none;
        }

        .menu-item {
            text-decoration: none;
            color: white;
        }
    }

    @media (max-width: 768px) {
        .menu {
            display: none;
            position: absolute;
            background-color: #680101;
            right: 0;
            left: 0;
            text-align: center;
            padding: 16px 0;

            li:hover {
                display: inline-block;
                background-color: maroon;
                transition: 0.3s ease;
            }

            li + li {
                margin-top: 12px;
            }
        }

        input[type=checkbox]:checked ~ .menu {
            display: block;
        }
        
        .hamburger {
            display: block;
        }
    }
}