.project-pane {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: white;
    border: 5px solid black;
    border-radius: 30px;
    width: 300px;
    height: 400px;
    word-wrap: break-word;
    overflow: hidden;

    .project-image {
        object-fit: cover;
        height: 50%;
    }

    .text-bottom {
        border-top: 5px solid black;
        background: white;
        height: fit-content;
        padding: 5px 10px 10px 10px;

        .project-title {
            font-size: 24px;
            text-align: center;
            margin: 5px 0px;
        }

        .tools-container {
            display: flex;
            justify-content: center;
            margin: 5px 0px;
            
            .tools-text {
                display: inline-block;
                padding: 5px 14px;
                margin: 2px;
                border: 3px solid black;
                background-color: white;
                border-radius: 50px;
            }
        }

        .project-description {
            font-size: 15px;
        }
    
    }

    @media (max-width: 300px) {
        width: 90%;
    
        .project-image {
            object-fit: cover;
            height: 100%;
        }

        .tools-text {
            font-size: 13px;
        }

        .project-description {
            text-align: center;
        }
    }
}
