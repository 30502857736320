.bubble-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    word-wrap: break-word;
    text-align: center;

    .container-title {
        color: black;
        font-weight: 600;
        font-size: 30px;
    }

    .entry-container {
        margin: 15px 0px 10px 0px;

        .bubble-text {
            font-weight: 100;
            display: inline-block;
            padding: 5px 14px;
            margin: 2px;
            border: 3px solid black;
            background-color: white;
            border-radius: 50px;
        }
    }
}

@media (max-width: 600px) {
    .bubble-container {
        width: 100%;
        padding: 0px 50px;
    }
}