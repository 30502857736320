.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 60px 0px 0px 0px;

    .welcome-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        .greeting-header {
            text-align: center;
            font-size: 74px;
            margin: 0px 10px;
    
            .span-h:hover {
                color: yellow;
                transition: 0.2s ease;
            }
    
            .span-e:hover {
                color: orange;
                transition: 0.2s ease;
            }
    
            .span-l:hover {
                color: magenta;
                transition: 0.2s ease;
            }
    
            .span-l2:hover {
                color: chartreuse;
                transition: 0.2s ease;
            }
    
            .span-o:hover {
                color: orangered;
                transition: 0.2s ease;
            }
    
            .span-1:hover {
                color: red;
                transition: 0.2s ease;
            }
    
            .span-2:hover {
                color: green;
                transition: 0.2s ease;
            }
    
            .span-3:hover {
                color: blue;
                transition: 0.2s ease;
            }
        }
    }
    
    .contact-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        .email-text {
            text-align: center;
        }
    }

    @media (max-width: 900px) {
        height: 650px;
        justify-content: space-between;

        .welcome-section {
            display: flex;
            text-align: center;

            .greeting-header {
                font-size: 48px;
                text-align: center;
            }
        }
    }
}


