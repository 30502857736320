.experience {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #C62121;
    padding-bottom: 100px;

    .experience-header {
        color: white;
        font-weight: 600;
        font-size: 50px;
        padding: 40px 0px 20px 0px;
    }
}