.typewriter-container {
    margin: 30px 10px 50px 10px;

    .typewriter-text {
        font-size: 50px;
        // margin-right: 50px;
    }

    @keyframes cursor-blink {
        0% {
          opacity: 0;
        }
    }

    .typewriter-text:after {
        content: "";
        width: 10px;
        height: 50px;
        background: black;
        display: inline-block;
        animation: cursor-blink 1s steps(2) infinite;
        margin-left: 10px;
    }

    @media (max-width: 900px) {

        .typewriter-text {
            text-align: center;
            font-size: 36px;
        }
    }
}
