.experience-node {
    display: flex;
    width: 500px;
    word-wrap: break-word;
    padding: 20px 0px 20px 0px;

    .image-link {
        width: fit-content;
    }

    .company-image {
        object-fit: cover;
        height: 100px;
        width: 100px;
        border: 5px solid black;
        margin: 0px 15px 0px 0px;
    }

    .company-image:hover {
        -o-transition:.5s;
        -ms-transition:.5s;
        -moz-transition:.5s;
        -webkit-transition:.5s;
        transition:.5s;
        box-shadow: 10px 10px 5px #888888;
    }

    .description-panel {
        margin: 0px 0px 0px 15px;
        padding: 5px 8px;

        .role-text {
            color: white;
            font-weight: 100;
            font-size: 35px;
        }

        .company-text {
            color: white;
            font-weight: 900;
            font-size: 18px;
        }

        .dates-text {
            color: white;
            font-weight: 500;
            font-size: 18px;
        }

        .description-box {
            background-color: white;
            margin: 15px 0px 0px 0px;
            padding: 5px 10px;
            border: 5px solid black;
            border-radius: 5px;

            .description-text {
                font-weight: 100;
                font-size: 16px;
                margin: 5px 0px;
                list-style: square;
            }
        }
    }
}

@media (max-width: 500px) {
    .experience-node {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;

        .company-image {
            margin: 0px;
        }

        .description-panel {
            margin: 0px;
        }
    }
}