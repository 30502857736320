.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #E9DCC9;

    .projects-header {
        color: black;
        font-weight: 600;
        font-size: 50px;
        padding: 40px 0px 20px 0px;
    }

    .projects-grid {
        display: inline-grid;
        grid-template-columns: 1fr 1fr;
        width: 800px;
        place-items: center;
        row-gap: 2em;
        margin: 30px 0px 70px 0px;
    }

    @media (max-width: 800px) {
        .projects-grid {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }
}