@import './variables';
@import './navigation';
@import './about';
@import './typewriter';
@import './contacts';
@import './experience';
@import './experiencetree';
@import './experiencenode';
@import './skills';
@import './bubblecontainer';
@import './projects';
@import './projectpane';

html {
    scroll-behavior: smooth;
    overflow-x: hidden;  
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    // font-family: 'Inter', sans-serif;
    font-family: 'Futura';
}

a {
    text-decoration: none;
}