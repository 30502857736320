.contacts {
    display: flex;
    justify-content: space-between;
    width: 250px;
    margin: 20px 0px 70px 0px;

    .social-image {
        width: 50px;
    }

    .social-image:hover {
        transition: 0.3s ease;
        opacity: 50%;
    }
}

@media (max-width: 310px) {
    .contacts {
        display: flex;
        width: 90%;
    }
}